import React, { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import useFormatDate from './hooks/useFormatDate'

const DateButton = forwardRef(
    ({ className, isPlaceholder, isFocused, ...props }, ref) => (
        <button
            ref={ref}
            type="button"
            className={twMerge(
                'px-4 py-3 w-ful text-sm flex-1 leading-[22px] text-center',
                isPlaceholder && 'text-gray-200',
                isFocused && 'outline outline-2 outline-blue-bright',
                className
            )}
            {...props}
        />
    )
)

const DateRangeButton = ({
    hasError,
    disabled,
    className,
    placeholder,
    returnRef,
    returnDate,
    departureRef,
    departureDate,
    returnIsFocused,
    onClickReturnDate,
    departureIsFocused,
    onClickDepartureDate,
}) => {
    const format = useFormatDate()
    return (
        <div
            className={twMerge(
                'border rounded w-full flex items-center',
                !hasError && 'border-gray-100 hover:border-gray-300',
                hasError && 'border-primary',
                className
            )}
        >
            <DateButton
                ref={departureRef}
                disabled={disabled}
                data-testid="DateRangeButton"
                onClick={onClickDepartureDate}
                isPlaceholder={!departureDate}
                isFocused={departureIsFocused}
                className="text-center rounded-l whitespace-nowrap"
            >
                {departureDate
                    ? format(departureDate, 'EEEEEE dd MMM')
                    : placeholder}
            </DateButton>
            <hr className="border-l border-gray-100 h-8 w-px mx-[2px]" />
            <DateButton
                ref={returnRef}
                disabled={disabled}
                onClick={onClickReturnDate}
                isPlaceholder={!returnDate}
                isFocused={returnIsFocused}
                className="text-center rounded-r whitespace-nowrap"
            >
                {returnDate ? format(returnDate, 'EEEEEE dd MMM') : placeholder}
            </DateButton>
        </div>
    )
}

export default DateRangeButton
