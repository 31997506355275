import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Listbox } from '@headlessui/react'
import { countries, getEmojiFlag } from 'countries-list'
import Input from './input'

export default function FormPhoneInput({
    hasError,
    placeholder,
    phoneFormProps,
    phoneCountryFormProps,
}) {
    return (
        <div
            className={twMerge(
                'flex rounded focus-within:outline focus-within:outline-1 focus-within:outline-blue-bright',
                hasError && 'border border-primary hover:border-primary-dark'
            )}
        >
            <Listbox id="phoneCountry" {...phoneCountryFormProps}>
                {({ open }) => (
                    <div className="relative">
                        <Listbox.Button className="relative w-full rounded-r-none border-gray-100 hover:border-r-gray-300 border py-3 px-4 pr-6 leading-[22px] rounded text-sm">
                            {({ value }) => `+${countries[value]?.phone?.[0]}`}
                        </Listbox.Button>
                        {open ? (
                            <Listbox.Options
                                static
                                className="absolute mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm z-popover"
                            >
                                {Object.keys(countries).map((key) => {
                                    const phoneCountry = countries[key]

                                    return (
                                        <Listbox.Option
                                            key={key}
                                            value={key}
                                            className={({ active, selected }) =>
                                                twMerge(
                                                    'relative select-none py-2 px-4 whitespace-nowrap',
                                                    active || selected
                                                        ? 'bg-blue-bright text-white'
                                                        : 'text-gray-900'
                                                )
                                            }
                                        >
                                            {`${getEmojiFlag(key)} ${
                                                phoneCountry.native
                                            } +(${phoneCountry.phone[0]})`}
                                        </Listbox.Option>
                                    )
                                })}
                            </Listbox.Options>
                        ) : null}
                    </div>
                )}
            </Listbox>
            <Input
                id="phone"
                placeholder={placeholder}
                inputClassName={twMerge(
                    'border-l-0 rounded-l-none focus:outline-none',
                    hasError && 'border-none'
                )}
                {...phoneFormProps}
            />
        </div>
    )
}
